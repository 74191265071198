/* Custom page CSS
-------------------------------------------------- */

/* Not required for template or sticky footer method. */

@font-face {
  font-family: 'digital7-mono';
  src: url('./static/fonts/digital-7.mono.ttf');
  descent-override: 0%;
}

main>.container {
  padding: 30px 15px 0;
}

.footer>.container {
  padding-right: 15px;
  padding-left: 15px;
  /* text-align: center; */
}

.container.mt-main {
  margin-top: 5rem !important;
}

.navbar-light {
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

code {
  font-size: 80%;
}

.logo {
  margin-right: 5px;
  vertical-align: top;
  width: 32px;
  height: 32px;
}

.logo-text {
  color: #6002d2;
  font-weight: bolder;
}

.logo-text:hover {
  color: #6002d2;
}


footer .list-inline>li>a::after {
  content: "|";
  padding-left: 10px;
}

footer .list-inline>li:last-child>a::after {
  content: none;
}

pre {
  overflow: visible;
}

.lang-list .list-inline>li>a::after {
  content: "|";
  padding-left: 10px;
}

.lang-list .list-inline>li:last-child>a::after {
  content: none;
}

.counts-info {
  font-size: small;
}

.timer-main .google-auto-placed {
  display: none;
}

.timer {
  position: relative;
  font-size: 145px;
  font-family: 'digital7-mono';
  color: #9137ff;
  background-color: black;
}

.alarmclock-main .google-auto-placed {
  display: none;
}

.alarmclock {
  position: relative;
  font-size: 210px;
  font-family: 'digital7-mono';
  color: #9137ff;
  background-color: black;
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  border-radius: var(--bs-card-border-radius);
}

.alarmclock-current {
  display: none;
  position: absolute;
  top: 5%;
  left: 10%;
  height: 30px;
  font-size: 40px;
  line-height: 25px;
}

.alarmclock-functions {
  display: flex;
  position: absolute;
  top: 5%;
  right: 10%;
  height: 30px;
}

.alarmclock-functions svg {
  display: flex;
  padding-left: 5px;
  width: 35px;
  height: 35px;
}

.alarmclock-functions span {
  font-size: 20px;
  text-align: center;
  margin-left: 5px;
}

.alarmclock-actives {
  display: flex;
  font-size: 20px;
  position: absolute;
  bottom: 5%;
  /* border: 1px solid red; */
  height: 43px;
}

.alarmclock-actives span {
  display: flex;
  padding-left: 90px;
  line-height: 20px;
  text-align: center;
}

.timer input[type="number"] {
  width: 135px;
  background-color: inherit;
  color: inherit;
  border: none;
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.timer input[type="number"]:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.timer-ms {
  width: 265px !important;
}

.btn-timer {
  padding: 10px 50px 10px 50px;
  font-size: 30px;
}

.btn-timer span {
  display: block;
  font-size: 15px;
  font-style: italic;
}

.btn-shortcut {
  font-size: 25px;
  width: 115px
}

.dateTime {
  text-align: center;
  font-size: xx-large;
  width: 45%;
  margin-right: 10px;
}

.dateTime-container {
  place-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.btn-wrap {
  white-space: normal !important;
}

.container.no-ad>.google-auto-placed {
  display: none !important;
}

.all-no-ad ins {
  display: none !important;
}

.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.btn-clipboard {
  position: absolute;
  top: .5rem;
  right: .5rem;
  z-index: 10;
  display: block;
  padding: .25rem .5rem;
  font-size: 75%;
  color: #818a91;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-radius: .25rem;
}

.btn-clipboard:hover {
  color: #fff;
  background-color: #027de7;
}

.text-result {
  background-color: #f7f7f9;
  height: 293px;
  overflow: auto;
  width: 100%;
  margin-bottom: 7px;
}

.text-result pre {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}


/* Landscape tablets and medium desktops */

@media (min-width: 992px) and (max-width: 1199px) {
  .timer {
    font-size: 110px;
  }

  .timer input[type="number"] {
    width: 140px;
  }

  .timer-ms {
    width: 205px !important;
  }

  .alarmclock {
    font-size: 125px;
  }

  .alarmclock-current {
    top: 12%;
    left: 10%;
    height: 30px;
    font-size: 27px;
    line-height: 5px;
  }
  
  .alarmclock-functions {
    top: 7%;
    right: 5%;
    height: 23px;
  }
  
  .alarmclock-actives {
    font-size: 15px;
    height: 24px;
  }
  
  .alarmclock-actives span {
    padding-left: 43px;
    line-height: 12px;
  }
}

/* Portrait tablets and small desktops */

@media (min-width: 768px) and (max-width: 991px) {
  .timer {
    font-size: 90px;
  }

  .timer input[type="number"] {
    width: 115px;
  }

  .timer-ms {
    width: 165px !important;
  }

  .btn-timer {
    padding: 10px 25px 10px 25px;
    font-size: 25px;
  }

  .btn-timer span {
    font-size: 19px;
  }

  .alarmclock {
    font-size: 125px;
  }

  .alarmclock-current {
    top: 12%;
    left: 10%;
    height: 30px;
    font-size: 27px;
    line-height: 5px;
  }
  
  .alarmclock-functions {
    top: 7%;
    right: 5%;
    height: 23px;
  }
  .alarmclock-functions svg {
    width: 25px;
    height: 25px;
  }

  
  .alarmclock-actives {
    font-size: 15px;
    height: 24px;
  }
  
  .alarmclock-actives span {
    padding-left: 43px;
    line-height: 12px;
  }
 

}

/* Landscape phones and portrait tablets */

@media (min-width: 575.99px) and (max-width: 767px) {
  .timer {
    font-size: 45px;
  }

  .timer input[type="number"] {
    width: 60px;
  }

  .timer-ms {
    width: 85px !important;
  }

  .btn-timer {
    padding: 10px 25px 10px 25px;
    font-size: 15px;
  }

  .btn-timer span {
    font-size: 9px;
  }

  .alarmclock {
    font-size: 72px;
  }

  .alarmclock-current {
    top: 12%;
    left: 10%;
    height: 30px;
    font-size: 20px;
    line-height: 5px;
  }
  
  .alarmclock-functions {
    top: 7%;
    right: 5%;
    height: 15px;
  }

  .alarmclock-functions svg {
    width: 20px;
    height: 20px;
  }
  
  .alarmclock-actives {
    font-size: 10px;
    height: 18px;
  }
  
  .alarmclock-actives span {
    padding-left: 25px;
    line-height: 9px;
  }
 
 
}

/* Portrait phones and smaller */

@media (min-width: 280.99px) and (max-width: 575.98px) {
  .timer {
    font-size: 40px;
  }

  .timer input[type="number"] {
    width: 50px;
  }

  .timer-ms {
    width: 75px !important;
  }

  .btn-timer {
    padding: 10px 25px 10px 25px;
    font-size: 15px;
  }

  .btn-timer span {
    font-size: 9px;
  }

  .alarmclock {
    font-size: 65px;
  }

  .alarmclock-current {
    top: 12%;
    left: 10%;
    height: 30px;
    font-size: 20px;
    line-height: 5px;
  }
  
  .alarmclock-functions {
    top: 2%;
    right: 5%;
    height: 15px;
  }
  
  .alarmclock-functions svg {
    width: 20px;
    height: 20px;
  }
  
  .alarmclock-actives {
    font-size: 10px;
    height: 18px;
  }
  
  .alarmclock-actives span {
    padding-left: 25px;
    line-height: 9px;
  }
 

}

/* To embed very smaller */

@media (max-width: 321.98px) {
  
  .alarmclock {
    font-size: 50px;
  }

  .alarmclock-current {
    top: 12%;
    left: 10%;
    height: 30px;
    font-size: 20px;
    line-height: 5px;
  }
  
  .alarmclock-functions {
    top: 7%;
    right: 5%;
    height: 12px;
  }

  .alarmclock-functions svg {
    width: 15px;
    height: 15px;
  }
  
  .alarmclock-actives {
    font-size: 8px;
    height: 13px;
  }
  
  .alarmclock-actives span {
    padding-left: 9px;
    line-height: 7px;
  }

}